import React from 'react';
import { Edit } from 'react-admin';
import RoomForm from './form';

const RoomEdit = props => (
    <Edit {...props} undoable={false}>
        <RoomForm/>
    </Edit>
);

export default RoomEdit;