import React from 'react';
import { Edit, useRefresh } from 'react-admin';
import HotelForm from './form';
import useFileTransform from '../utils/useFileTransform';

const HotelEdit = props => {
    const refresh = useRefresh();
    return (
        <Edit {...props} undoable={false} onSuccess={()=>refresh()} transform={useFileTransform()}>
            <HotelForm/>
        </Edit>
    );
}

export default HotelEdit;