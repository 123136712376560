const authProvider = {
    login: ({ username, password }) =>  {
        return fetch('/api/users/login',
            {
                method: 'POST',
                body: JSON.stringify({ email:username, password }),
                headers: new Headers({ 'Content-Type': 'application/json' })
            })
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(user => {
                localStorage.setItem('user', JSON.stringify(user));
            });
    },
    logout: () => {
        localStorage.removeItem('user');
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('user') ? Promise.resolve() : Promise.reject();
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 /*|| status === 403*/) {
            localStorage.removeItem('user');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const user = localStorage.getItem('user');
        if(!user) return Promise.reject();
        const { permissions } = JSON.parse(user);
        return permissions ? Promise.resolve(permissions) : Promise.reject();
    },
    getIdentity: () => {
        const user = localStorage.getItem('user');
        if(!user) return Promise.reject();
        const { id, displayName, avatar } = JSON.parse(user);
        return Promise.resolve({ id, fullName:displayName, avatar });
    },
};

export default authProvider;