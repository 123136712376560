import React from 'react';
import { List, Datagrid, TextField, BooleanField, Filter, TextInput, ReferenceField, DateTimeInput, DateField, FunctionField } from 'react-admin';

const OrdersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn resettable/>
    </Filter>
);

const OrdersList = props => (
    <List {...props} exporter={false} bulkActionButtons={false} filters={<OrdersFilter/>} sort={{ field: 'fromDate', order: 'DESC' }}>
        <Datagrid rowClick="edit">
            <TextField source="roomNumber" label="Номер" sortable={true}/>
            <DateField source="fromDate" label="Заезд" sortable={true}/>
            <DateField source="toDate" label="Выезд" sortable={true}/>
            <FunctionField label="Гостей" render={o => o?.guests.length} />
            <ReferenceField label="Предпочтительный язык" source="preferredLanguageId" reference="languages" emptyText="-" link={false}>
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>
);

export default OrdersList;