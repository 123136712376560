import React, { useEffect, useState } from 'react';
import { Toolbar,SaveButton, ArrayInput, FileField, FileInput, SimpleForm, SimpleFormIterator, useInput, required } from 'react-admin';
import Divider from '@material-ui/core/Divider';
import deepEqual from 'deep-equal'


import Form from '../layout/form';


import {NumberInput} from '../components/input';

import ApkParser  from 'app-info-parser/src/apk';

const CustomToolbar = props => {
    //console.log('props',props);
    return (
    <Toolbar {...props} >
        <SaveButton disabled={props.pristine}/>
    </Toolbar>
)};

const ApkInput = (props)=>{
    const input = useInput(props);
    const {input:{ value, onChange }, meta: {initial}} = input;
    //console.log('value',value)
    useEffect(async ()=>{
        if(value?.file?.rawFile){
            const info = await new ApkParser(value?.file.rawFile).parse();
            console.log(info);
            var error = undefined;
            if(initial){
                if(initial.package && initial.package!=info.package){
                    error = 'Имя пакета не совпадает';
                } else if(initial.code && initial.code>=info.versionCode){
                    error = 'Код версии ниже или равен предыдущему';
                }
            }
            onChange({...value, package: info.package, code: info.versionCode, version: info.versionName, error});
        } else {
            //console.log('deee',initial!=value,deepEqual(initial.file, value.file))
            if(initial!=value){
                if(deepEqual(initial.file, value.file))
                    onChange(initial)
                else if(initial?.package!=value.package || initial?.code!=value.code || initial?.version!=value.version)
                    onChange({...value, package: initial.package, code: initial.code, version: initial.version, error: undefined});
            }
        }
    },[value?.file]);

    const {source, ...p} = props;
    return (
        <>
        <FileInput source={`${source}.file`} validate={apkValidate} {...p}/>
        <p style={{margin:0}}>Имя пакета: {value.package}</p>
        <p style={{margin:0}}>Код версии: {value.code}</p>
        <p style={{margin:0}}>Имя версии: {value.version}</p>
        </>
    );
}

const apkValidate = (value, values)=>{
    return values.apk?.error;
}

const AppSettingsForm = ({...props}) =>(
    <Form>
        {
            (language)=>(
                <SimpleForm {...props} toolbar={<CustomToolbar/>} redirect='edit'>
                    <FileInput source={`background`} label={`Фон`}>
                        <FileField source="src" title="title" target="_blank"/>
                    </FileInput>
                    <FileInput source={`welcomeImage`} label={`Изображение на странице привествия`}>
                        <FileField source="src" title="title" target="_blank"/>
                    </FileInput>
                    <ArrayInput source="screensavers" label="Заставки">
                        <SimpleFormIterator>
                            <FileInput source={`image`} label={``}>
                                <FileField source="src" title="title" target="_blank"/>
                            </FileInput>
                            <NumberInput source={`order`} label="Порядок"/>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <Divider style={{marginTop:20, marginBottom:20}}/>
                    <NumberInput source="bannersPerImageDuration" label="Длительность показа каждого баннера (сек)" min={0} validate={required()} />
                    <NumberInput source="bannersShowInterval" label="Интервал показа баннера (сек)" min={0} validate={required()} />
                    <ArrayInput source="banners" label="Баннеры">
                        <SimpleFormIterator>
                            <FileInput source={`image`} label={``}>
                                <FileField source="src" title="title" target="_blank"/>
                            </FileInput>
                            <NumberInput source={`order`} label="Порядок"/>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <Divider style={{marginTop:20, marginBottom:20}}/>
                    <ApkInput source="apk" label={`Файл приложения`}>
                        <FileField source="src" title="title" target="_blank"/>
                    </ApkInput>
                </SimpleForm>
            )
        }
    </Form>
);

export default AppSettingsForm;