import React from 'react';
import { BooleanInput, FileField, FileInput, FormDataConsumer, required, SimpleForm } from 'react-admin';
import Form from '../layout/form';
import { ColorInput } from 'react-admin-color-input';

import {TextInput, SelectInput, NumberInput} from '../components/input';
import LangTextInput from '../components/input/multilang/text';


const ApplicationForm = ({...props}) =>(
    <Form>
        {
            (language, langs)=>(
                <SimpleForm {...props}>
                    <TextInput source="name" label="Название" validate={required()}/>
                    <LangTextInput langs={langs} language={language} source="title" label="Отображаемое название"/>
                    <ColorInput source="color" label="Цвет" validate={required()}/>
                    <TextInput source="package" label="Пакет" validate={required()}/>
                    <NumberInput source="order" label="Порядок отображения"/>
                    <BooleanInput source="isSystem" label="Системное" validate={required()} defaultValue={false}/>
                    <FormDataConsumer>
                                    {
                                        ({formData, scopedData, getSource, ...rest})=>(
                                            !formData.isSystem && (<FileInput source="installer" label="Установщик (APK-файл)" validate={required()}>
                                                <FileField source="src" title="title" target="_blank"/>
                                            </FileInput>)
                                        )
                                    }
                    </FormDataConsumer>
                </SimpleForm>
            )
        }
    </Form>
);

export default ApplicationForm;