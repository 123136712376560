import React from 'react';
import { Edit, useRefresh } from 'react-admin';
import AppSettingsForm from './form';
import useFileTransform from '../utils/useFileTransform';

const AppSettingsEdit = props => {
    const refresh = useRefresh();
    return (
        <Edit {...props} undoable={false} transform={useFileTransform()} redirect='edit' onSuccess={()=>refresh()}>
            <AppSettingsForm/>
        </Edit>
    );
}

export default AppSettingsEdit;