import React from 'react';
import { List, Datagrid, TextField, BooleanField, Filter, TextInput, NumberField} from 'react-admin';


const ApplicationsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn resettable/>
    </Filter>
);

const ApplicationsList = props => (
    <List {...props} exporter={false} bulkActionButtons={false} filters={<ApplicationsFilter/>} sort={{ field: 'order', order: 'ASC' }}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Название" sortable={true}/>
            <TextField source="package" label="Пакет" sortable={false}/>
            <BooleanField source="isSystem" label="Системное" sortable={false}/>
            <NumberField source="order" label="Порядок отображения" sortable={true}/>
        </Datagrid>
    </List>
);

export default ApplicationsList;