import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import dataProvider from './dataProvider';
import authProvider from './authProvider';
import russian from './i18n/ru';

import MainLayout from './layout/main';

import languages from './languages';
import services from './services';
import news from './news';
import rooms from './rooms';
import roomOrders from './roomOrders';
import hotels from './hotels';
import appSettings from './appSettings';
import applications from './applications';

const i18nProvider = polyglotI18nProvider(()=>russian, 'ru');

function App() {
  return (
    <Admin title="Hotels"
           authProvider={authProvider}
           dataProvider={dataProvider}
           i18nProvider={i18nProvider}
           layout={MainLayout}
    >
      <Resource name="news" {...news}/>
      <Resource name="rooms" {...rooms}/>
      <Resource name="roomOrders" {...roomOrders}/>
      <Resource name="services" {...services}/>
      <Resource name="languages" {...languages}/>
      <Resource name="hotel" {...hotels}/>
      <Resource name="appSettings" {...appSettings}/>
      <Resource name="applications" {...applications}/>
    </Admin>
  );
}

export default App;
