import React from 'react';
import { Create } from 'react-admin';
import ApplicationForm from './form';
import useFileTransform from '../utils/useFileTransform';

const ApplicationCreate = props => (
    <Create {...props} transform={useFileTransform()}>
        <ApplicationForm/>
    </Create>
);

export default ApplicationCreate;