import React from 'react';
import { required, SimpleForm } from 'react-admin';
import Form from '../layout/form';

import {TextInput, SelectInput, NumberInput} from '../components/input';


const RoomForm = ({...props}) =>(
    <Form>
        {
            (language)=>(
                <SimpleForm {...props}>
                    <TextInput source="number" label="Номер" validate={required()}/>
                </SimpleForm>
            )
        }
    </Form>
);

export default RoomForm;