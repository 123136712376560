import React from 'react';
import { ArrayInput, BooleanInput, FileField, FileInput, FormDataConsumer, FormTab, required, SimpleFormIterator, TabbedForm } from 'react-admin';
import Form from '../layout/form';
import viewModes from './viewModes'
import {TextInput, SelectInput, NumberInput, LangTextInput, LangFileInput} from '../components/input';

const ServiceForm = ({...props}) =>(
    <Form>
        {
            (language,langs)=>(
                <TabbedForm {...props} sanitizeEmptyValues={true}>
                    <FormTab label="Описание">
                        <TextInput source="name" label="Имя"  validate={required()}/>
                        <BooleanInput source="isVisible" label="Включена"/>
                        <SelectInput source="viewMode" label="Вид" choices={viewModes}/>
                        <LangTextInput source="title" label="Отоброжаемое имя" langs={langs} language={language}/>
                        <LangTextInput source="contact" label="Контакты" langs={langs} language={language}/>
                        <NumberInput source={`order`} label="Порядок"/>
                        <LangTextInput source="description" label="Описание" langs={langs} language={language} fullWidth multiline/>
                        <LangFileInput source="image" label="Изображение" langs={langs} language={language}/>
                    </FormTab>
                    <FormTab label="Категории">
                        <ArrayInput source="categories" label="">
                            <SimpleFormIterator>
                                <TextInput source="name" label="Имя" validate={required()}/>
                                <LangTextInput source="title" label="Отоброжаемое имя" langs={langs} language={language}/>
                                <NumberInput source={`order`} label="Порядок"/>
                                <FormDataConsumer>
                                    {
                                        ({...rest})=>{
                                            if(rest.scopedFormData)
                                            {
                                                if(!rest.scopedFormData.id)
                                                    rest.scopedFormData.id=`NEW_${Date.now()}`;
                                            }
                                        }
                                    }
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>
                    <FormTab label="Пункты">
                        <ArrayInput source="items" label="">
                            <SimpleFormIterator>
                                <LangTextInput source="description" label="Описание для вида: Плитка" langs={langs} language={language}/>
                                <NumberInput source={`order`} label="Порядок"/>
                                <LangFileInput source="image" label="Изображение" langs={langs} language={language}/>
                                <FormDataConsumer>
                                    {
                                        ({formData, scopedData, getSource, ...rest})=>(
                                            <SelectInput source={getSource("categoryId")} label="Категория" allowEmpty optionText="name" emptyText="Без категории" choices={formData.categories} fullWidth/>
                                        )
                                    }
                                </FormDataConsumer>
                                
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>
                </TabbedForm>
            )
        }
    </Form>
);

export default ServiceForm;