import React from 'react';
import { FormDataConsumer, FileInput, FileField } from 'react-admin';
import Typography from '@material-ui/core/Typography';

import style from '../styles'
import valueForLang from './utils'


const LangFileInput = ({language,langs, label, source, ...props}) =>{
    return (
        <FormDataConsumer>
        {
            ({formData, scopedData, getSource, ...rest})=>{
                const values = formData[source]?.values;
                const used = valueForLang(values, language, langs);
                return (
                    <div style={{marginBottom: 20}}>
                        <FileInput label={`${label} (${language.name})`} source={`${source}.values.${language.id}`}>
                            <FileField source="src" title="title" target="_blank"/>
                        </FileInput>
                        { used && used.language.id!==language.id &&
                            (
                                <div style={(props.fullWidth?{}:style)}>
                                    <Typography variant="subtitle2">
                                        Используется значение от {used.language.name}:
                                    </Typography>
                                    <a href={used.value.src} title="title" target="_blank">{used.value.title}</a>
                                </div>
                            )
                        }
                    </div>
                );
            }
        }
    </FormDataConsumer>
    );
}

export default LangFileInput;