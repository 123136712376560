import React from 'react';
import { Create } from 'react-admin';
import OrdersForm from './form';

const OrdersCreate = props => (
    <Create {...props}>
        <OrdersForm/>
    </Create>
);

export default OrdersCreate;