import React from 'react';
import { Edit } from 'react-admin';
import NewsForm from './form';

const NewsEdit = props => (
    <Edit {...props} undoable={false}>
        <NewsForm/>
    </Edit>
);

export default NewsEdit;