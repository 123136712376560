import React from 'react';
import { ArrayInput, BooleanInput, FormTab, ReferenceInput, required, SimpleFormIterator, TabbedForm, useInput } from 'react-admin';

import { TextInput, SelectInput } from '../components/input';

const Header = (props) => {
    const { input: { value } } = useInput(props);
    return (
        <p>{value.description}</p>
        );
}

const LanguageForm = ({hideResources ,...props}) =>(
    <TabbedForm {...props}>
        <FormTab label="Описание">
            <TextInput source="name" label="Имя"  validate={required()}/>
            <TextInput source="key" label="Краткое название(ISO 639-1)"  validate={required()}/>
            <BooleanInput source="isDefault" label="По умолчанию" />
            <ReferenceInput source="fallbackLanguageId" reference="languages" label="Запасной язык" allowEmpty emptyText="Без языка">
                <SelectInput allowEmpty emptyText="Использовать язык по умолчанию" optionText="name"/>
            </ReferenceInput>
        </FormTab>
        <FormTab label="Ресуры" hidden={hideResources}>
            <ArrayInput source="resources" label="">
                <SimpleFormIterator disableAdd={true} disableRemove={true}>
                    <Header/>
                    <TextInput source="value" label="Значение" fullWidth multiline/>
                </SimpleFormIterator>
            </ArrayInput>
        </FormTab>
    </TabbedForm>
);

export default LanguageForm;