import React from 'react';
import { ArrayInput, BooleanInput, DateInput, FormTab, ReferenceInput, required, SimpleFormIterator, TabbedForm } from 'react-admin';
import Form from '../layout/form';

import {TextInput, SelectInput, AutocompleteInput} from '../components/input';


const OrdersForm = ({...props}) =>(
    <Form>
        {
            (language)=>(
                <TabbedForm {...props}>
                    <FormTab label="Общая информация">
                        <ReferenceInput source="roomId" reference="rooms" label="Номер" validate={required()}>
                            <AutocompleteInput optionText="number"/>
                        </ReferenceInput>
                        <DateInput source="fromDate" label="Заезд" validate={required()}/>
                        <DateInput source="toDate" label="Выезд" validate={required()}/>
                        <ReferenceInput source="preferredLanguageId" reference="languages" label="Предпочтительный язык" allowEmpty emptyText="Без предпочтений">
                            <SelectInput optionText="name"/>
                        </ReferenceInput>
                        <BooleanInput source="showWelcome" label="Показать привествие"/>
                    </FormTab>
                    <FormTab label="Гости">
                        <ArrayInput source="guests" label="Список">
                            <SimpleFormIterator>
                                <TextInput source="firstName" label="Имя" validate={required()}/>
                                <TextInput source="lastName" label="Фамилия"/>
                                <TextInput source="middleName" label="Отчество"/>
                                <DateInput source="birthDay" label="Дата рождения" validate={required()}/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>
                </TabbedForm>
            )
        }
    </Form>
);

export default OrdersForm;