import React from 'react';
import { List, Datagrid, TextField, BooleanField, Filter, TextInput, ReferenceField } from 'react-admin';

const LanguageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn resettable/>
    </Filter>
);

const LanguageList = props => (
    <List {...props} exporter={false} bulkActionButtons={false} filters={<LanguageFilter/>} sort={{ field: 'name', order: 'ASC' }}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Имя" sortable={true}/>
            <BooleanField source="isDefault" label="По умолчанию"/>
            <ReferenceField source="fallbackLanguageId" reference="languages" label="Запасной язык">
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>
);

export default LanguageList;