import React from 'react';
import { List, Datagrid, TextField, BooleanField, Filter, TextInput, ReferenceField, DateTimeInput, DateField } from 'react-admin';


const AppSettingsList = props => (
    <List {...props} exporter={false} bulkActionButtons={false} sort={{ field: 'id', order: 'ASC' }}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID" sortable={true}/>
        </Datagrid>
    </List>
);

export default AppSettingsList;