import React from 'react';
import { Create } from 'react-admin';
import RoomForm from './form';

const RoomCreate = props => (
    <Create {...props}>
        <RoomForm/>
    </Create>
);

export default RoomCreate;