import React from 'react';
import { Toolbar,SaveButton, ArrayInput, FileField, FileInput, required, SimpleForm, SimpleFormIterator } from 'react-admin';
import Form from '../layout/form';

import {LangTextInput, NumberInput} from '../components/input';

const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveButton disabled={props.pristine}/>
    </Toolbar>
);

const HotelForm = ({...props}) =>(
    <Form>
        {
            (language, langs)=>(
                <SimpleForm {...props} toolbar={<CustomToolbar/>} redirect='edit'>
                    <LangTextInput source="title" label="Заголовок" language={language} langs={langs}/>
                    <LangTextInput source="description" label="Описание" fullWidth multiline language={language} langs={langs}/>
                    <ArrayInput source="images" label="Изображения">
                            <SimpleFormIterator>
                                <LangTextInput source="description" label="Описание" language={language} langs={langs}/>
                                <NumberInput source={`order`} label="Порядок"/>
                                <FileInput source={`file`} label={`Изображение`}>
                                    <FileField source="src" title="title" target="_blank"/>
                                </FileInput>
                            </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            )
        }
    </Form>
);

export default HotelForm;