import React from 'react';
import { Edit } from 'react-admin';
import OrdersForm from './form';

const OrdersEdit = props => (
    <Edit {...props} undoable={false}>
        <OrdersForm/>
    </Edit>
);

export default OrdersEdit;