import russianMessages from 'ra-language-russian';

export default {
    ...russianMessages,
    ra:{
        ...russianMessages.ra,
        auth:{
            ...russianMessages.ra.auth,
            username: "Почта"
        }
    },
    resources:{
        languages:{
            name: "Языки"
        },
        services:{
            name:"Сервисы"
        },
        news:{
            name:"Новости"
        },
        rooms:{
            name:"Номера"
        },
        roomOrders:{
            name:"Брони"
        },
        hotel:{
            name:"Об отеле"
        },
        appSettings:{
            name: "Настройки приложения"
        },
        applications:{
            name: "Приложения"
        }
    }
};
