import React from 'react';
import { List, Datagrid, TextField, BooleanField, Filter, TextInput, ReferenceField, DateTimeInput, DateField } from 'react-admin';

const RoomsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn resettable/>
    </Filter>
);

const RoomsList = props => (
    <List {...props} exporter={false} bulkActionButtons={false} filters={<RoomsFilter/>} sort={{ field: 'number', order: 'ASC' }}>
        <Datagrid rowClick="edit">
            <TextField source="number" label="Номер" sortable={true}/>
        </Datagrid>
    </List>
);

export default RoomsList;