import React from 'react';
import { Edit } from 'react-admin';
import ServiceForm from './form';
import useFileTransform from '../utils/useFileTransform';

const ServiceEdit = props => {
    return (
        <Edit {...props} undoable={false} transform={useFileTransform()}>
            <ServiceForm/>
        </Edit>
    );
}

export default ServiceEdit;