import React from 'react';
import { Create } from 'react-admin';
import LanguageForm from './form';

const LanguageCreate = props => (
    <Create {...props}>
        <LanguageForm hideResources={false}/>
    </Create>
);

export default LanguageCreate;