import React from 'react';
import { Edit } from 'react-admin';
import ApplicationForm from './form';
import useFileTransform from '../utils/useFileTransform';

const ApplicationEdit = props => (
    <Edit {...props} undoable={false} transform={useFileTransform()}>
        <ApplicationForm/>
    </Edit>
);

export default ApplicationEdit;