import React from 'react';
import { DateTimeInput, required, SimpleForm } from 'react-admin';
import Form from '../layout/form';

import {TextInput, LangTextInput} from '../components/input';


const NewsForm = ({...props}) =>(
    <Form>
        {
            (language,langs)=>(
                <SimpleForm {...props}>
                    <TextInput source="name" label="Имя" validate={required()}/>
                    <DateTimeInput source="fromDate" label="С даты"/>
                    <DateTimeInput source="toDate" label="До даты"/>
                    <LangTextInput source="text" label="Текст" fullWidth multiline language={language} langs={langs}/>
                </SimpleForm>
            )
        }
    </Form>
);

export default NewsForm;