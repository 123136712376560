import React from 'react';
import { List, Datagrid, TextField, BooleanField, Filter, TextInput, ReferenceField, DateTimeInput, DateField } from 'react-admin';

const NewsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn resettable/>
    </Filter>
);

const NewsList = props => (
    <List {...props} exporter={false} bulkActionButtons={false} filters={<NewsFilter/>} sort={{ field: 'name', order: 'ASC' }}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Имя" sortable={true}/>
            <DateField source="fromDate" label="Ротация с даты"/>
            <DateField source="toDate" label="до даты"/>
        </Datagrid>
    </List>
);

export default NewsList;