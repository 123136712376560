import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { useTranslate, MenuItemLink, usePermissions } from 'react-admin';
import { Box } from '@material-ui/core';

import CommentIcon from '@material-ui/icons/Comment';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import InfoIcon from '@material-ui/icons/Info';
import DescriptionIcon from '@material-ui/icons/Description';
import CategoryIcon from '@material-ui/icons/Category';
import PeopleIcon from '@material-ui/icons/People';
import ForumIcon from '@material-ui/icons/Forum';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import MapIcon from '@material-ui/icons/Map';

const CustomMenu = ({onMenuClick, dense, ...props}) => {
    const [state, setState] = useState({
    });

    const translate = useTranslate();
    const open = useSelector((state) => state.admin.ui.sidebarOpen);

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };


    return (
    <Box mt={1}>
        <MenuItemLink
                to={`/news`}
                primaryText={translate(`resources.news.name`, {smart_count: 2,})}
                leftIcon= {<CommentIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
        <MenuItemLink
                to={`/rooms`}
                primaryText={translate(`resources.rooms.name`, {smart_count: 2,})}
                leftIcon= {<CommentIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
        <MenuItemLink
                to={`/roomOrders`}
                primaryText={translate(`resources.roomOrders.name`, {smart_count: 2,})}
                leftIcon= {<CommentIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
        <MenuItemLink
                to={`/services`}
                primaryText={translate(`resources.services.name`, {smart_count: 2,})}
                leftIcon= {<CommentIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
        <MenuItemLink
                to={`/languages`}
                primaryText={translate(`resources.languages.name`, {smart_count: 2,})}
                leftIcon= {<CommentIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
        <MenuItemLink
                to={`/hotel/d39ae29a-9482-4567-baed-356439eef87c`}
                primaryText={translate(`resources.hotel.name`, {smart_count: 2,})}
                leftIcon= {<CommentIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
        <MenuItemLink
                to={`/appSettings/a18e7522-6a17-43e0-87f6-b91dba0ff9fc`}
                primaryText={translate(`resources.appSettings.name`, {smart_count: 2,})}
                leftIcon= {<CommentIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
        <MenuItemLink
                to={`/applications`}
                primaryText={translate(`resources.applications.name`, {smart_count: 2,})}
                leftIcon= {<CommentIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
    </Box>
    );
};

export default CustomMenu;