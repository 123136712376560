import React, { useCallback, useEffect, useState } from 'react';
import {useGetList} from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const Form = ({children})=>{
    const { data, ids, loading, error } = useGetList('languages', { page:1, perPage:1000 }, {field:'name', order:'asc'});
    const [ language, setLanguage ] = useState();

    useEffect(()=>{
        for(const id of ids){
            if(data[id].isDefault){
                setLanguage(data[id]);
                return;
            }
        }
    },[data,ids,setLanguage])

    const handleChange = useCallback((e)=>setLanguage(data[e.target.value]),[data,setLanguage]);

    if(loading || !language) return (<div>Loading</div>);

    return (
        <React.Fragment>
            <FormControl variant="filled" style={{display:'flex'}} >
                <InputLabel id="lang">редактируемый язык</InputLabel>
                <Select id="lang" value={language?.id} onChange={handleChange}>
                    {
                        ids.map((id)=>(
                            <MenuItem key={id} value={id}>{data[id].name}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            {
                children(language, data)
            }
        </React.Fragment>
    );
}

export default Form;