import React from 'react';
import { List, Datagrid, TextField, BooleanField, Filter, TextInput, ReferenceField } from 'react-admin';

const ServicesFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn resettable/>
    </Filter>
);

const ServicesList = props => (
    <List {...props} exporter={false} bulkActionButtons={false} filters={<ServicesFilter/>} sort={{ field: 'name', order: 'ASC' }}>
        <Datagrid rowClick="edit">
            <TextField source="name" label="Имя" sortable={true}/>
            <BooleanField source="isVisible" label="Включена"/>
        </Datagrid>
    </List>
);

export default ServicesList;