const getDefault=(langs)=>{
    for(const l of Object.values(langs)){
        if(l.isDefault){
            return l;
        }
    }
    return null;
}

const valueForLang=(values, language, langs)=>{
    if(!values || !langs) return null;
    var value = values[language.id];
    if(value) {
        return {
            language,
            value
        };
    }
    if(language.fallbackLanguageId){
        value = values[language.fallbackLanguageId];
        if(value) {
            return {
                language : langs[language.fallbackLanguageId],
                value
            };
        }
    }
    const def = getDefault(langs);
    if(!def) return null;
    var value = values[def.id];
    if(value) {
        return {
            language: def,
            value
        };
    }
    return null;
}

export default valueForLang;