import React from 'react';
import { Create } from 'react-admin';
import ServiceForm from './form';
import useFileTransform from '../utils/useFileTransform';

const ServiceCreate = props => (
    <Create {...props} transform={useFileTransform()}>
        <ServiceForm hideResources={false}/>
    </Create>
);

export default ServiceCreate;